export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone
export const selectPlant = state => state.plant
export const selectSector = (state, id) => state.sectors.allById[id]
export const selectLocationIdsBySector = state => state.locations.idsBySector
export const selectLocation = (state, id) => state.locations.allById[id]
export const selectLocationReport = (state, id) => state.locations.reportById[id]
export const selectSectorIds = state => state.sectors.allIds
export const selectSectorReport = (state, id) => state.sectors.reportById[id]
export const selectConfigRegular = (state) => state.config.regular
export const selectConfigFresh = (state) => state.config.fresh
