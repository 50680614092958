import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectLocation,
  selectLocationReport
} from 'reports/dashboard/selectors'
import { reportShape } from 'reports/dashboard/shapes'
import { Characters } from 'lib/const'
import ReportGraph from 'components/ReportGraph'
import { selectIsNavigationInThePast } from 'containers/Navigation/selectors'
import DashboardProductionPerformanceGraph from './DashboardProductionPerformanceGraph'
import DashboardLocationLink from 'reports/dashboard/DashboardLocationLink'

const DashboardLocation = ({ id, report, isInThePast, children }) => {
  return (
    <div className='report-item'>
      <div className='report-item__box'>
        <div className='report-item__title'>
          <DashboardLocationLink id={id} />

          <div className='report__changeover-count'>
            <code>{report.changeoverCount[0]}</code>
            {' / '}
            <code>{report.changeoverCount[1]}</code>
          </div>
        </div>

        <ReportGraph values={report.production} character={Characters.PRODUCTION} small showLabel={false} />
        <ReportGraph values={report.changeover} character={Characters.CHANGEOVER} small showLabel={false} />
        <ReportGraph values={report.stoppagePlanned} character={Characters.STOPPAGE_PLANNED} small showLabel={false} />
        <ReportGraph values={report.stoppageUnplanned} character={Characters.STOPPAGE_UNPLANNED} small showLabel={false} />
        <ReportGraph values={report.scrap} character={Characters.SCRAP} small showLabel={false} />
        {
          isInThePast &&
          <DashboardProductionPerformanceGraph values={report.productionPerformance} character={Characters.STOPPAGE} small showLabel={false} />
        }
        {children}
      </div>
    </div>
  )
}

DashboardLocation.propTypes = {
  id: PropTypes.number.isRequired,
  report: reportShape,
  isInThePast: PropTypes.bool,
  children: PropTypes.any
}

const mapStateToProps = (state, { id }) => ({
  ...selectLocation(state, id),
  report: selectLocationReport(state, id),
  isInThePast: selectIsNavigationInThePast(state)
})

export default connect(mapStateToProps)(DashboardLocation)
