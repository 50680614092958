import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Loader from 'components/Loader'
import DashboardHeader from 'reports/dashboard/DashboardHeader'
import DashboardTree from 'reports/dashboard/DashboardTree'
import ReportGraph from 'components/ReportGraph'
import DashboardProductionPerformanceGraph from './DashboardProductionPerformanceGraph'
import { selectPlant } from 'reports/dashboard/selectors'
import { selectIsNavigationInThePast } from 'containers/Navigation/selectors'
import { reportShape } from 'reports/dashboard/shapes'
import { Characters } from 'lib/const'
import { StickyContainer } from 'react-sticky'

const Dashboard = ({ isLoaded, plant, isInThePast }) => {
  const { report } = plant
  if (isLoaded) {
    return (
      <StickyContainer>
        <DashboardHeader />

        <div className='report__graphs'>
          <div className='report__changeover-count'>
            <FormattedMessage id='dashboard.graph.realizationChangeovers' defaultMessage='Realized changeovers' />
            {': '}
            <code>{report.changeoverCount[0]}</code>
            {' / '}
            <FormattedMessage id='dashboard.graph.plannedChangeovers' defaultMessage='Planned changeovers' />
            {': '}
            <code>{report.changeoverCount[1]}</code>
          </div>

          <div className='clearfix' />

          <ReportGraph values={report.production} character={Characters.PRODUCTION} />
          <ReportGraph values={report.changeover} character={Characters.CHANGEOVER} />
          <ReportGraph values={report.stoppagePlanned} character={Characters.STOPPAGE_PLANNED} />
          <ReportGraph values={report.stoppageUnplanned} character={Characters.STOPPAGE_UNPLANNED} />
          <ReportGraph values={report.scrap} character={Characters.SCRAP} />
          { isInThePast && <DashboardProductionPerformanceGraph values={report.productionPerformance} character={Characters.STOPPAGE} /> }
        </div>

        <DashboardTree />
      </StickyContainer>
    )
  } else {
    return <Loader full />
  }
}

Dashboard.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  isInThePast: PropTypes.bool,
  plant: PropTypes.shape({
    name: PropTypes.string,
    report: reportShape
  }).isRequired
}

const mapStateToProps = (state) => ({
  isLoaded: state.init.isFinished,
  plant: selectPlant(state),
  isInThePast: selectIsNavigationInThePast(state)
})

export default connect(mapStateToProps)(Dashboard)
