import { fork, call, put, select, all, takeLatest, race, take, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import Actions, {
  startInit,
  finishInit,
  receiveSectors,
  receiveLocations,
  receiveReports
} from 'reports/dashboard/actions'
import heartbeat from 'lib/saga/heartbeat'
import {
  selectToken,
  selectTimezone,
  selectConfigRegular,
  selectConfigFresh
} from 'reports/dashboard/selectors'
import { SET_NAVIGATION, configureNavigation, setNavigationDate } from 'containers/Navigation/actions'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { TYPE_WEEK_DAY_SHIFT } from 'containers/Navigation/const'

const AUTORELOAD_INTERVAL = 60 * 1000

function * init () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)

  yield put(startInit())

  yield put(configureNavigation(TYPE_WEEK_DAY_SHIFT))
  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone)))

  const [sectors, locations] = yield all([
    call(api.get, '/sectors', { token }),
    call(api.get, '/locations', { token })
  ])

  yield all([
    put(receiveSectors(sectors)),
    put(receiveLocations(locations))
  ])

  yield put(finishInit())
}

function * reports () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)
  const params = yield select(selectNavigationRange, timezone)
  const regular = yield select(selectConfigRegular)
  const fresh = yield select(selectConfigFresh)

  if (regular && !fresh) params.regular = true
  if (!regular && fresh) params.fresh = true

  const reports = yield call(api.get, '/reports/dashboard', { params, token })

  yield put(receiveReports(reports))
}

function * reload () {
  let result

  while (true) {
    result = yield race({
      action: take(SET_NAVIGATION),
      timeout: delay(AUTORELOAD_INTERVAL)
    })

    if (result.timeout) {
      yield call(reports)
    }
  }
}

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'Dashboard')
  }

  yield call(init)
  yield call(reports)
  yield takeLatest([SET_NAVIGATION, Actions.SET_REGULAR, Actions.SET_FRESH], reports)

  yield fork(reload)
}
