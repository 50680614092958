import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import ReportHeader from "components/ReportHeader";
import ToggleButton from 'components/ToggleButton'
import { selectPlant, selectConfigRegular, selectConfigFresh } from "reports/dashboard/selectors";
import { setRegular, setFresh } from "reports/dashboard/actions";

const messages = defineMessages({
  fresh: {
    id: 'dashboard.header.fresh',
    defaultMessage: 'New',
    description: 'Fresh projects filter'
  },
  regular: {
    id: 'dashboard.header.regular',
    defaultMessage: 'Regular',
    description: 'Regular only projects filter'
  },
})

const DashboardReportHeader = ({ onRegularChange, onFreshChange, regular, fresh, intl, ...rest }) => {
  return (
    <ReportHeader {...rest}>
      <ToggleButton isToggled={regular} onToggleOn={() => onRegularChange(true)} onToggleOff={() => onRegularChange(false)}>
        {intl.formatMessage(messages.regular)}
      </ToggleButton>
      <ToggleButton isToggled={fresh} onToggleOn={() => onFreshChange(true)} onToggleOff={() => onFreshChange(false)}>
        {intl.formatMessage(messages.fresh)}
      </ToggleButton>
    </ReportHeader>
  );
};

const mapStateToProps = (state) => ({
  regular: selectConfigRegular(state),
  fresh: selectConfigFresh(state),
  name: selectPlant(state).name,
  sticky: true,
});

const mapDispatchToProps = {
  onRegularChange: setRegular,
  onFreshChange: setFresh
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DashboardReportHeader));
