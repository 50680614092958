import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Progress from 'components/Progress'
import { FormattedMessage } from 'react-intl'
import { Characters } from 'lib/const'
import ReportGraphDetails from 'components/ReportGraphDetails'

const DashboardProductionPerformanceGraph = ({ values, small, showLabel }) => {
  let percentage
  const [produced, expected] = values

  if (expected === 0 && produced > 0) {
    percentage = 100.0
  } else if (expected === 0 && produced === 0) {
    percentage = 0.0
  } else {
    percentage = Math.round(1000 * produced / expected) / 10.0
  }

  return (
    <div className={classnames('report-graph', `report-graph--warning`, { 'report-graph--small': small })}>
      {
        showLabel &&
        <div className='report-graph__label'>
          <span className='report-graph__character'>
            <FormattedMessage id='dashboard.graph.productionPerformance' defaultMessage='MA Performance' />
          </span>
          <span className='report-graph__percentage'>
            { isNaN(percentage) ? '---' : percentage.toFixed(1) + '%' }
          </span>
        </div>
      }

      <div className='report-graph__content'>
        {
          <ReportGraphDetails planned={expected} realized={produced} character={Characters.PRODUCTION} />
        }

        <div className='report-graph__graph'>
          {
            <Progress small={small} color={'warning'} value={percentage} valueVisible={false} />
          }
        </div>
      </div>
    </div>
  )
}

DashboardProductionPerformanceGraph.defaultProps = {
  small: Progress.defaultProps.small,
  showLabel: true
}

DashboardProductionPerformanceGraph.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  small: Progress.propTypes.small,
  showLabel: PropTypes.bool
}

export default DashboardProductionPerformanceGraph
