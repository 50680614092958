import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectSector,
  selectSectorReport
} from 'reports/dashboard/selectors'
import { reportShape } from 'reports/dashboard/shapes'
import { Characters } from 'lib/const'
import ReportGraph from 'components/ReportGraph'
import { selectIsNavigationInThePast } from 'containers/Navigation/selectors'
import DashboardProductionPerformanceGraph from './DashboardProductionPerformanceGraph'

const DashboardSector = ({ id, report, sector, isInThePast, children }) => {
  return (
    <div className='report-section'>
      <div className='report-section__data'>
        <h1 className='report-section__title'>
          {sector.name}

          <div className='report__changeover-count'>
            <code>{report.changeoverCount[0]}</code>
            {' / '}
            <code>{report.changeoverCount[1]}</code>
          </div>
        </h1>
        <div className='report-section__graphs'>
          <ReportGraph values={report.production} character={Characters.PRODUCTION} small showLabel={false} />
          <ReportGraph values={report.changeover} character={Characters.CHANGEOVER} small showLabel={false} />
          <ReportGraph values={report.stoppagePlanned} character={Characters.STOPPAGE_PLANNED} small showLabel={false} />
          <ReportGraph values={report.stoppageUnplanned} character={Characters.STOPPAGE_UNPLANNED} small showLabel={false} />
          <ReportGraph values={report.scrap} character={Characters.SCRAP} small showLabel={false} />
          {
            isInThePast &&
            <DashboardProductionPerformanceGraph values={report.productionPerformance} character={Characters.STOPPAGE} small showLabel={false} />
          }
        </div>
      </div>

      <div className='report-section__locations'>
        {children}
      </div>
    </div>
  )
}

DashboardSector.propTypes = {
  id: PropTypes.number.isRequired,
  isInThePast: PropTypes.bool,
  children: PropTypes.any,
  report: reportShape,
  sector: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
}

const mapStateToProps = (state, { id }) => ({
  report: selectSectorReport(state, id),
  sector: selectSector(state, id),
  isInThePast: selectIsNavigationInThePast(state)
})

export default connect(mapStateToProps)(DashboardSector)
